/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear;
}
@keyframes l5 {
  0%   {box-shadow: 20px 0 #93c5fd, -20px 0 #93c5fd; background: #93c5fd}
  20%  {box-shadow: 20px 0 #93c5fd, -20px 0 #1d4ed8; background: #93c5fd}
  60%  {box-shadow: 20px 0 #93c5fd, -20px 0 #93c5fd; background: #1d4ed8 }
  90%  {box-shadow: 20px 0 #1d4ed8, -20px 0 #93c5fd; background: #93c5fd }
  100% {box-shadow: 20px 0 #93c5fd, -20px 0 #93c5fd; background: #93c5fd}
}
/* @keyframes l5 {
    0%  {box-shadow: 20px 0 #1d4ed8, -20px 0 #93c5fd;background: #1d4ed8 }
    33% {box-shadow: 20px 0 #1d4ed8, -20px 0 #93c5fd;background: #93c5fd}
    66% {box-shadow: 20px 0 #93c5fd,-20px 0 #1d4ed8; background: #93c5fd}
    100%{box-shadow: 20px 0 #93c5fd,-20px 0 #1d4ed8; background: #1d4ed8 }
} */