/* File to handle the animation of the WhatsAppFloater component. */

.animate-floater-show {
  animation: floater-show 0.5s forwards;
}
.animate-floater-hide {
  animation: floater-hide 0.5s forwards;
}

@keyframes floater-show {
  from {
    transform: translateX(150%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes floater-hide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(150%);
  }
}