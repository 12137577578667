body {
  background-color: white;
  min-height: 100vh;
  background-size: cover;
  background-attachment: fixed;
}

#root {
  width: 100%;
  display: flex;
  justify-content: center;
}
  
.app-wraper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  min-height: 100vh;
}

.preview-wrapper {
  width: 100%;
  transition: all ease-in-out 0.5s;
  transform: translateY(200px);
  opacity: 0;
}

.preview-wrapper.float {
  transition: all ease-in-out 0.3s;
  transform: translateY(50px);
  opacity: 1;
}

#previewer {
  box-shadow: rgba(50, 50, 93, 0.25) 0px -6px 12px -2px, rgba(0, 0, 0, 0.3) 0px -3px 7px -3px;
}

.item-wrapper, .description-buttons-wrapper {
  min-height: 110px;
}

.item-description {
  min-height: 50px;
}

.modal-body  {
  max-height: 70vh;
}

/* @media (max-height: 700px) {
  #previewer {
    display: none;
  }
} */

/* Home Page */

.site-example-img {
  position: relative;
  width: 280px;
}
