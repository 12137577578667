@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "./App.css";

html {
  scroll-behavior: smooth;
}

.accordion-button:not(.collapsed)::after, .accordion-button::after {
  background-image: none;
  content: none !important;
}


input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { -webkit-appearance: none; -moz-appearance: none; appearance: none; }

.accordion-body {
  padding-top: 25px;
  box-shadow: rgb(204, 219, 232) 0 -3px 6px 0px inset;
}

body {
  padding: 0;
}
